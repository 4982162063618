import { appWithTranslation } from 'next-i18next'
import Script from 'next/script'
import '../styles/globals.css'
import '../styles/device.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/400.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/300.css'

import type { AppProps } from 'next/app'
import { AppProvider } from '@palenca/ui'

function WrappedApp({ Component, pageProps }: AppProps) {
  return (
    <AppProvider>
      <Component {...pageProps} />
      <Script
        id="google-tag-manager"
        type="text/javascript"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            <!-- Google Tag Manager -->
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MBF4JR3');
            <!-- End Google Tag Manager -->
        `
        }}
      />
      <Script
        type="text/javascript"
        strategy="beforeInteractive"
        src="https://cdn.weglot.com/weglot.min.js"
      />
      <Script
        id="weglot-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `Weglot.initialize({
            api_key: 'wg_bc53116c4972ecf82359197279c44cbb4'
        });`
        }}
      />
    </AppProvider>
  )
}

export default appWithTranslation(WrappedApp)
