// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENVIRONMENT = process.env.NODE_ENV
const enableSentry = ENVIRONMENT !== 'production'



if (enableSentry) {
    Sentry.init({
        dsn: SENTRY_DSN || 'https://2b533a9f069e48daa97e9ec3cdb7508c@o542553.ingest.sentry.io/6447914',
        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: 1.0,
        beforeSend(event, hint) {
            // Do not send open replay errors
            const error = hint.originalException;
            if (
                error &&
                error.message &&
                error.message.match(/limit has been reached/i)
            ) {
                return null
            }
            return event;
        },
        // ...
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
    });
}